﻿.product-image,
.content .product-image {
	padding: 24px 0 30px;
	border-top: 3px dotted $color-black;
	border-bottom: 3px dotted $color-black;
	margin-bottom: 25px;

	&__name {
		@include font-product-title();
		text-align: center;
		margin-bottom: 7px;
	}
}