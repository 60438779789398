﻿.highlights, .content-block .highlights {
	list-style: none;
	width: 100%;
	padding: 0;
	margin-bottom: 27px;

	&__item {
		@include font-list();
		position: relative;
		padding: 9px 20px 10px 46px;
		border-top: 1px solid $color-border;

		&:nth-child(odd) {
			background: $color-grey;
		}

		&:last-child {
			border-bottom: 1px solid $color-border;
		}

		&:before {
			@include icon($icon-check);
			position: absolute;
			top: 13px;
			left: 15px;
			color: $color-red;
			font-size: 16px;
		}
	}
}
