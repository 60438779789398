﻿.landing-pages {
	@extend .container;
	max-width: 1030px;
	padding: 9px 0 0;
	border-top: 1px solid rgba(#000, 0.2);
	text-align: center;

	&__link {
		@include font-legal();
		margin: 0 14px 2px;
		white-space: nowrap;

		&:hover {
			text-decoration: underline;	
		}
	}
}
