﻿.recipes {
	overflow: hidden;
	margin-top: 29px;
	margin-bottom: -8px;

	&__no-results {
		margin: 57px 0 17px;
	}
}

.recipe {

	&__link {
		display: block;
		margin-bottom: 28px;

		&:hover .recipe__image {
			transform: scale(1.1);
		}
	}

	&__image-container {
		@include responsive-container(400, 300);

		.-wide & {
			@include responsive-container(408, 205);
			float: left;
			width: 50%;
		}

		.-high & {
			@include responsive-container(392, 452);
		}
	}

	&__image {
		position: relative;
		transition: transform 0.3s ease-in-out;
		transform: scale(1);
	}

	&__content {
		@include clearfix();
		padding: 20px 10px 29px;
		border: 1px solid $color-border;
		border-top: none;

		.-wide & {
			display: flex;
			align-items: center;
			float: left;
			width: 50%;
			padding: 20px 30px 29px;
			border: none;
			background: url(../../images/kraft-background/kraft-background-426.png)
		}
	}

	&__title {
		@include font-product-title;
		text-align: center;
		margin-bottom: 14px;

		.-wide & {
			@include font-h2();
		}
	}

	&__preperation-time,
	&__amount-of-people {
		@include font-list();
		float: left;
		display: block;
		width: 100%;
		text-align: center;
		color: $color-alt;

		@include breakpoint(ml) {
			width: 50%;
		}

		&:before {
			position: relative;
			font-size: 24px;
		}

		.-wide & {
			color: $color-black;
		}
	}

	&__preperation-time {

		&:before {
			@include icon($icon-clock);
			top: 6px;
			right: 15px;
		}
	}

	&__amount-of-people {

		&:before {
			@include icon($icon-amount-of-people);
			top: 6px;
			right: 10px;
		}
	}

	&__text {
		float: left;
		text-align: center;
		margin-top: 28px;
	}
}