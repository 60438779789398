﻿.care-tips {
	list-style: none;
	padding: 0;
	margin: 0 0 -24px;
	text-align: center;

	&__wrapper {
		@extend .kraft-background;
	}

	&__item {
		position: relative;
		display: inline-block;
		width: 164px;
		padding-top: 84px;
		margin: 0 0 24px;

		&:before {
			position: absolute;
			content: '';
			width: 102px;
			height: 64px;
			margin-left: -51px;
			top: 0;
			left: 50%;
		}

		&.-in-the-sun {

			&:before {
				background: url(../../images/care-tips/in-the-sun.png);
			}
		}

		&.-in-half-sun-half-shadow {

			&:before {
				background: url(../../images/care-tips/half-sun-half-shadow.png);
			}
		}

		&.-inside {

			&:before {
				background: url(../../images/care-tips/inside.png);
			}
		}

		&.-outside {

			&:before {
				background: url(../../images/care-tips/outisde.png);
			}
		}

		&.-out-of-the-wind {

			&:before {
				background: url(../../images/care-tips/out-of-the-wind.png);
			}
		}

		&.-edible {

			&:before {
				background: url(../../images/care-tips/edible.png);
			}
		}

		&.-repot {

			&:before {
				background: url(../../images/care-tips/repot.svg);
			}
		}

		&.-in-the-sun {

			&:before {
				background: url(../../images/care-tips/in-the-sun.png);
			}
		}

		&.-in-the-sun {

			&:before {
				background: url(../../images/care-tips/in-the-sun.png);
			}
		}

		&.-temperature {

			&:before {
				background: url(../../images/care-tips/temperature.png);
			}
		}

		&.-irrigation {

			&:before {
				background: url(../../images/care-tips/irrigation.png);
			}
		}

		&.-plant-food {

			&:before {
				background: url(../../images/care-tips/plant-food.png);
				/*@include icon($icon-care-tip-plant-food);
				font-size: 102px;*/
			}
		}
	}
}
