﻿$font-path: "../fonts/";

$font-text: "Montserrat Regular", Arial, Helvetica Neue, Helvetica, sans-serif;;
$font-title: "Intro Black", $font-text;
$color-black: #000;
$color-white: #fff;
$color-brand: #016ea3;
$color-brand-dark: #041e42;
$color-red: #c30c1f;
$color-grey: #f8f8f8;
$color-border: #e6e6e6;
$color-alt: #c59875;

@mixin font-h1() {
	font: 30px/36px $font-title;
	text-transform: uppercase;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);

	@include breakpoint(l) {
		font-size: 36px;
		line-height: 46px;
	}
}

@mixin font-h2() {
	font: 24px/24px $font-title;
	text-transform: uppercase;
}

@mixin font-h3() {
	font: 18px/22px $font-text;
}

@mixin font-product-title() {
	font: 18px/24px $font-title;
	text-transform: uppercase;
}

@mixin font-menu() {
	font: 16px/1.2em $font-title;
	text-transform: uppercase;
}

@mixin font-list() {
	font: 16px/1.2em $font-text;
}

@mixin font-search() {
	font: 14px/24px $font-title;
	text-transform: uppercase;
}

@mixin font-text() {
	font: 14px/22px $font-text;
}

@mixin font-legal() {
	font: 12px/1.2em $font-text;
}

@mixin font-random-product() {
	font: 36px/36px $font-title;
	text-transform: uppercase;
}