﻿input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=date],
textarea,
select {
	@include font-text();
	padding: 8px 16px 10px;
	-webkit-appearance: none;
	outline: none;
	height: 40px;
	margin: 0;
	border: none;
	color: $color-black;
	background: $color-grey;
	border: 1px solid $color-border;
	border-radius: 0;
	overflow: hidden;

	&.input-validation-error {
		background: mix(red, #fff, 20%);
		border-color: mix(red, #fff, 40%);
	}

	&.required {
		border-left: 1px solid mix(red, #fff, 40%);
	}

	&.-borderless {
		border: none;
	}
}

input[type=date] {
	text-transform: uppercase;
}

input[type=file] {
	
	&.required {
		border-left: 2px solid mix(red, #fff, 40%);
	}
}

textarea {
	resize: vertical;
	overflow: auto;
	height: auto;
	min-height: 140px;

	&.-contact {
		min-height: 140px;
	}
}

label {
	@include font-text();
	padding: 8px 16px 10px 0;
	margin: 0;
}

.form-check {
	display: none;
}

.form__control {
	@include clearfix;
	margin-bottom: 10px !important;

	label {

		@include breakpoint(ts) {
			float: left;
			width: 107px;
		}
	}

	input,
	select,
	textarea {

		@include breakpoint(ts) {
			float: left;
			width: calc(100% - 107px);
		}
	}

	&.-right {
		text-align: right;
	}
}

.form__checkbox {
	@include clearfix;
	
	@include breakpoint(ts) {
		margin-bottom: 10px;
		padding-left: 212px;
	}
}
