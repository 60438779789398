﻿.faq-category {

	&__title {
		@include font-product-title();
		margin: 0 0 9px;
	}

	&__list {
		list-style: none;
		padding: 0;
		margin: 0 0 28px;
		border-bottom: 1px solid $color-border;
	}

	&__item {
		overflow: hidden;
		border-top: 1px solid $color-border;
		padding: 18px 30px 20px;
		cursor: pointer;

		&:nth-child(odd) {
			background: $color-grey;
		}

		.dropdown {

			p, ul, ol {

				&:first-child {
					margin-top: 18px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&[aria-selected="true"] {

			.faq-category__item-title:before {
				@include icon($icon-arrow-up);
			}
		}
	}

	&__item-title {
		@include font-list();
		position: relative;
		margin: 0;

		&:before {
			@include icon($icon-arrow-down);
			position: absolute;
			top: 0;
			right: 0;
			font-size: 10px;
		}
	}
}
