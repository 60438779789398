﻿.social-media {
	display: inline-block;
	overflow: hidden;
	padding: 0;
	margin: 0 0.5px 0 -7.5px;

	&.social-media--contact-page {
		width: 100%;
		padding-top: 10px;

		@include breakpoint(ml) {
			width: auto;
			padding-top: 0;
		}
	}
}

.social-media__item {
	float: left;
	display: block;
	padding: 0 5px;
}

.social-media__link {
	position: relative;
	display: block;
	overflow: hidden;
	width: 50px;
	height: 50px;
	text-indent: -9999px;
	transition: background 0.2s ease-in-out;
	color: $color-black;

	&:after {
		font-size: 28px;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		transform: translate(-50%, -50%);
		text-indent: 0;
		transition: transform 0.2s ease-in-out;
	}

	&.-facebook:after {
		@include icon($icon-facebook);
	}

	&.-youtube:after {
		//@include icon($icon-youtube);
	}

	&.-twitter:after {
		//@include icon($icon-twitter);
	}

	&.-linkedin:after {
		//@include icon($icon-linkedin);
	}

	&.-google-plus:after {
		//@include icon($icon-google-plus);
	}

	&.-soundcloud:after {
		//@include icon($icon-soundcloud);
	}

	&.-vimeo:after {
		//@include icon($icon-vimeo);
	}

	&.-instagram:after {
		@include icon($icon-instagram);
	}

	&.-flickr:after {
		//@include icon($icon-flickr);
	}

	&.-pinterest:after {
		@include icon($icon-pinterest);
	}

	&:hover {

		&:after {
			transform: translate(-50%, -50%) scale(1.2);
		}
	}
}