﻿.fading-text {
	position: relative;
	overflow: hidden;

	&:after {
		position: absolute;
		display: none;
		content: '';
		width: 100%;
		height: 50px;
		bottom: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 55%,rgba(255,255,255,1) 100%);
	}

	&.-contract {
		max-height: 265px;

		&:after {
			display: block;
		}
	}
	
	&__toggler {
		color: $color-red;

		&:before {
			content: attr(data-expand-text);
		}
	}
}
