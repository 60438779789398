﻿.slider {
	position: relative;
	overflow: hidden;
	padding: 0 0 42px;

	@include breakpoint(ml) {
		padding: 0 25px 42px;

		&:before {
			position: absolute;
			z-index: 1;
			content: '';
			width: 3px;
			height: 100%;
			top: 0;
			left: 25px;
			background: $color-white;
		}
	}

	&__item {
		float: left;
		width: 100%;
		border-color: transparent;
		border-style: solid;
		border-right-width: 16px;
		border-left-width: 16px;

		.-quin & {

			@include breakpoint(ml) {
				width: 50%;
			}

			@include breakpoint(t) {
				width: (100% / 3);
			}

			@include breakpoint(l) {
				width: 25%;
			}

			@include breakpoint(ll) {
				width: 20%;
			}
		}

		.-triple & {

			@include breakpoint(t) {
				width: 50%;
			}

			@include breakpoint(l) {
				width: (100% / 3);
			}
		}

		&.-recipe {
			margin-bottom: 29px;
		}
	}
}
