﻿.ingredients {
	overflow: hidden;
	background: $color-grey;
	padding: 26px 0 0;

	&__title {
		position: relative;
		cursor: pointer;
		margin-bottom: 19px;

		&:before {
			@include icon($icon-arrow-up);
			position: absolute;
			font-size: 18px;
			top: 0;
			right: 0;
		}

		&[aria-expanded="false"] {
		
			&:before {
				@include icon($icon-arrow-down);
			}
		}

		&__content {
			padding: 26px 0 10px;
		}
	}
}