﻿.link {
	@include font-list();
	text-decoration: none !important;
	cursor: pointer;

	&:after {
		@include icon($icon-arrow-right);
		position: relative;
		top: -1px;
		left: 5px;
		font-size: 6px;
		transition: left 0.3s ease-in-out;
	}

	&:hover:after {
		left: 8px;
	}
}
