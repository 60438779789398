﻿@mixin button-hover() {
	background-color: darken($color-red, 10%);
}

@mixin button-brand-hover() {
	background-color: darken($color-brand, 10%);
}

@mixin button-transparent-hover() {
	background-color: rgba($color-white, 0.4);
}

.button {
	@include font-menu();
	position: relative;
	z-index: 1;
	display: inline-block;
	padding: 16px 20px;
	transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
	-webkit-appearance: none;
	cursor: pointer;
	text-decoration: none;
	background-color: $color-red;
	color: $color-white;
	border: none;
	overflow: hidden;
	text-align: center;

	&:hover {
		@include button-hover();
	}

	&.-shadow {
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8);
	}

	&.-brand {
		background-color: $color-brand;

		&:hover {
			@include button-brand-hover();
		}
	}

	&.-small {
		padding: 11px 20px 11px 20px;
	}

	&.-arrow-down {
		@extend .-small;
		padding-right: 42px;

		&:after {
			@include icon($icon-arrow-down);
			position: absolute;
			top: 16px;
			right: 20px;
			font-size: 11px;
		}
	}

	&.-header {
		position: relative;
		margin: 0 20px;
		top: 13px;
	}

	&.-transparent {
		background: rgba($color-white, 0.2);
		color: $color-black;

		&:hover {
			@include button-transparent-hover();
		}
	}
}

.buttons {
	margin: -5px -5px 9px;

	.button {
		margin: 5px;
	}

	&.-gap-32 {
		margin: -5px -16px 9px;

		.button {
			margin: 5px 16px 32px;
		}
	}
}