﻿.filter {

	&__item {
		overflow: hidden;
	}

	&__item-inner {
		overflow: hidden;
		padding: 16px 30px 24px;
		background: $color-grey;
	}

	&__header {
		overflow: hidden;
	}

	.-checkbox {

		input {
			display: none;

			&:checked + label {

				&:after {
					opacity: 1;
				}
			}
		}

		label {
			position: relative;
			float: left;
			padding: 100%;
			padding: 7px 0 7px 32px;
			margin: 0;
			cursor: pointer;

			@include breakpoint(ml) {
				width: 50%;
			}

			@include breakpoint(ts) {
				width: (100% / 3);
			}

			@include breakpoint(t) {
				width: 25%;
			}

			&:before {
				position: absolute;
				content: '';
				width: 20px;
				height: 20px;
				border: 1px solid $color-border;
				top: 8px;
				left: 0;
				text-align: center;
				color: transparent;
				background: $color-white;
			}

			&:after {
				position: absolute;
				content: '';
				width: 18px;
				height: 18px;
				top: 9px;
				left: 1px;
				background: url(../../images/checked.png);
				opacity: 0;
			}

			&:hover:after {
				opacity: 0.2;
			}
		}
	}

	.-textbox {
		float: left;
		width: 100%;

		@include breakpoint(ts) {
			border-bottom: 3px dotted $color-black;
		}

		@include breakpoint(lm) {
			width: (100% / 6 * 2);
			border-bottom: none;
		}

		input {
			@include font-search();
			height: 70px;
			border: none;
			background: none;
			padding: 23px 16px;

			@include placeholder {
				@include font-search();
				color: $color-black;
			}
		}
	}

	&__title {
		@include font-menu();
		float: left;
		position: relative;
		width: 100%;
		padding: 19px 30px 17px;
		margin: 0;
		cursor: pointer;
		border-top: 3px dotted $color-black;

		@include breakpoint(ts) {
			width: 50%;
			border-top: none;

			&:nth-child(odd) {
				border-left: 3px dotted $color-black;
			}
		}

		@include breakpoint(l) {
			width: 25%;
			border-left: 3px dotted $color-black;

			&:first-of-type {
				border-left: none;
			}
		}

		@include breakpoint(lm) {
			width: (100% / 6);

			&:first-of-type {
				border-left: 3px dotted $color-black;
			}
		}

		&:after {
			@include icon($icon-arrow-down);
			position: absolute;
			margin-top: -6px;
			top: 50%;
			right: 16px;
			font-size: 12px;
		}

		&[aria-selected="true"] {
			background: $color-grey;

			&:after {
				@include icon($icon-arrow-up);
			}
		}

		&:hover {
			background: $color-grey;
		}
	}

	&__sub-title {
		@include font-text();
		display: block;
		text-transform: none;
	}
}
