.content-block {
	position: relative;
	padding: 56px 0 40px;

	@extend .rip-effect;
	@extend .content;

	+ .content-block {
		padding-top: 59px;

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			max-width: 1240px;
			height: 3px;
			border-bottom: 3px dotted $color-black;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&.-no-padding-bot {
		padding-bottom: 0;

		+ .content-block:after {
			display: none;
		}
	}
}

.content {

	p, ul:not(.faq-category__list), ol {
		margin-bottom: 22px;

		&:last-child {
			margin-bottom: 14px;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.bigger {
		@include font-list();
		font-weight: bold;
	}

	ul:not(.faq-category__list), ol {
		margin-top: 0;
		padding-left: 16px;
	}
}
