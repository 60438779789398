﻿.products {
	overflow: hidden;
	margin-top: 51px;
	margin-bottom: -41px;
}

.product {
	position: relative;
	display: block;
	padding: 21px 0 29px;
	border-top: 3px dotted $color-black;
	border-bottom: 3px dotted $color-black;
	margin-bottom: 60px;

	@include breakpoint(ml) {

		&:before {
			position: absolute;
			content: '';
			height: 100%;
			border-left: 3px dotted $color-black;
			top: 0;
			left: -16px;
		}
	}

	&__title {
		@include font-product-title;
		text-align: center;
		margin-bottom: 7px;
	}

	&__image-container {
		@include responsive-container(286, 318);
		margin-bottom: 30px;
	}

	&__link {
		@include font-menu();
		display: block;
		background: $color-grey;
		color: $color-black !important;
		text-align: center;
		padding: 16px 0 15px;
		text-decoration: none !important;
		transition: background 0.3s ease-in-out;

		&:hover {
			background: darken($color-grey, 8%);
		}
	}

	&__wrapper {

		@include breakpoint(ml) {
			&:nth-child(2n + 1) .product:before {
				display: none;
			}
		}

		@include breakpoint(t) {

			&:nth-child(2n + 1) .product:before {
				display: block;
			}

			&:nth-child(3n + 1) .product:before {
				display: none;
			}
		}

		@include breakpoint(l) {

			&:nth-child(3n + 1) .product:before {
				display: block;
			}

			&:nth-child(4n + 1) .product:before {
				display: none;
			}
		}
	}
}