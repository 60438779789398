﻿.image-bar {
	@extend .rip-effect-bottom;
	margin-bottom: 1px;
	overflow: hidden;

	&:first-child {

		&:after {
			display: none;
		}
	}

	&__item {
		float: left;
		width: 100%;

		@include breakpoint(ts) {
			width: 50%;

			&:nth-child(3) {
				float: right;
			}
		}

		@include breakpoint(l) {
			width: 25%;

			&:nth-child(3) {
				float: left;
			}
		}
	}

	+ .call-to-action:after {
		display: none;
	}
}
