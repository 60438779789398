﻿.container {
	@include clearfix;
	width: 100%;
	max-width: 1070px;
	padding: 0 20px;
	margin: 0 auto;

	&.-relative {
		position: relative;
		z-index: 2;
	}

	&.-title {
		text-align: center;
	}

	&.-large {
		max-width: 1280px;
	}

	&.-small {
		max-width: 962px;
	}

	&.-slideshow {
		max-width: 580px;
	}
}
