﻿// Intro Black
@font-face {
	font-family: 'Intro Black';
	src: url('../../fonts/Intro-Black.eot');
	src: url('../../fonts/Intro-Black.eot?#iefix') format('embedded-opentype'), url('../../fonts/Intro-Black.woff2') format('woff2'), url('../../fonts/Intro-Black.woff') format('woff'), url('../../fonts/Intro-Black.svg') format('truetype');
	font-weight: normal;
	font-style: normal;
}

// Montserrat
@font-face {
	font-family: 'Montserrat Regular';
	src: url('../../fonts/Montserrat-Regular.eot');
	src: url('../../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/Montserrat-Regular.woff2') format('woff2'), url('../../fonts/Montserrat-Regular.woff') format('woff'), url('../../fonts/Montserrat-Regular.svg') format('truetype');
	font-weight: normal;
	font-style: normal;
}