﻿.slick-arrow {
	position: absolute;
	z-index: 1;
	width: 50px;
	height: 50px;
	margin-top: 0;
	border: none;
	bottom: 20px;
	text-indent: -9999px;
	transition: background 0.2s ease-in-out;
	background: $color-grey;

	&:before {
		position: absolute;
		font-size: 22px;
		top: 50%;
		transform: translateY(-50%);
		text-indent: 0;
	}

	&:hover {
		background: $color-grey;
	}
}

.slick-prev {
	left: 50%;
	margin-left: -50px;

	&:before {
		@include icon($icon-arrow-left);
		left: 15px;
		transition: left 0.3s ease-in-out;
	}

	&:hover:before {
		left: 13px;
	}
}

.slick-next {
	right: 50%;
	margin-right: -50px;

	&:before {
		@include icon($icon-arrow-right);
		right: 15px;
		transition: right 0.3s ease-in-out;
	}

	&:hover:before {
		right: 13px;
	}
}