﻿.nav-main {
	position: relative;
	overflow: hidden;

	@include breakpoint(l) {
		height: 200px;
	}

	ul {
		float: left;
		width: 100%;
		padding: 0 0 210px;
		margin: 0;
		list-style: none;
		overflow: hidden;

		&.left {
			padding: 0;
		}

		@include breakpoint(l) {
			float: left;
			width: 50%;
			padding-bottom: 0;
			padding-top: 66px;

			&.left {
				padding-right: 204px;
				padding-top: 66px;
				text-align: right;
			}

			&.right {
				padding-left: 204px;
			}
		}

		> li {
			display: block;
			margin: 0 !important;
			overflow: hidden;

			@include breakpoint(l) {
				display: inline-block;
			}

			> a {
				@include font-menu();
				text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
				z-index: 1;
				position: relative;
				display: block;
				padding: 15px 8.5px 14px;
				color: $color-white;
			}

			&:hover,
			&.active {
				background: rgba($color-brand, 0.8);
			}
		}
	}
}
