﻿.fontpage-content {
	display: flex;
	height: 100%;
	align-items: center;
	text-align: center;
	background: url(../../images/kraft-background/kraft-background-426.png);

	&__content {
		padding: 38px 42px 20px
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	&__wrapper {
		margin: 43px 0 20px;
	}
}
