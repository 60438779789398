﻿.logo {
	position: absolute;
	float: left;
	display: block;
	width: 160px;
	height: 100px;
	margin: 0;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	text-indent: -9999px;
	background: url(../../images/logo-pick-and-joy.png) no-repeat;
	//background-image: url(../../images/logo-pick-and-joy.png), none;
	background-size: 100% auto;

	&__wrapper {
		position: absolute;
		z-index: 1;
		width: 251px;
		height: 161px;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background: url(../../images/logo-background.png) no-repeat;
		//background-image: url(../../images/logo-background.png), none;
	}
}
