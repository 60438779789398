﻿.scroll-down {
	position: absolute;
	height: 40px;
	width: 40px;
	margin-left: -20px;
	left: 50%;
	bottom: 20px;

	@include breakpoint(t) {
		height: 190px;
		bottom: 0;
	}

	&:before {
		position: absolute;
		content: '';
		width: 40px;
		height: 40px;
		top: 0;
		left: 0;
		background: url(../../images/scroll-down.png);
		transition: top 0.3s ease-in-out;
	}

	&:after {
		position: absolute;
		content: '';
		width: 13px;
		height: 130px;
		margin-left: -5.5px;
		bottom: 0;
		left: 50%;
		background: url(../../images/dots.png);
		display: none;

		@include breakpoint(t) {
			display: block;
		}
	}

	&:hover:before {
		top: 4px;
	}
}
