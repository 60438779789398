﻿.image {

	&__wrapper {
		margin: 0 0 20px;
		background: grey;

		&.-big {
			@include responsive-container(1030, 420);
			margin-top: 28px;
		}

		&.-small {
			width: 100%;
			height: auto;

			@include breakpoint(ml) {
				width: 202px;
				height: 134px;
			}

			@include breakpoint(t) {
				width: 392px;
				height: 261px;
			}
		}

		&.-right {
			float: right;
			margin-top: 3px;
			margin-left: 32px;
		}
	}
}

.images {

	img {
		margin-bottom: 20px;
	}
}