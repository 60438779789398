﻿.kraft-background {
	position: relative;
	padding: 57px 0 54px;
	background: url(../../images/kraft-background/kraft-background-426.png);

	@extend .rip-effect-kraft;

	@include breakpoint(ml) {
		background-image: url(../../images/kraft-background/kraft-background-768.png);
	}

	@include breakpoint(t) {
		background-image: url(../../images/kraft-background/kraft-background-1024.png);
	}

	@include breakpoint(l) {
		background-image: url(../../images/kraft-background/kraft-background-1440.png);
	}

	@include breakpoint(ll) {
		background-image: url(../../images/kraft-background/kraft-background-1920.png);
	}

	/*&.-frontpage {
		margin-top: 41px;

		@include breakpoint(ml) {
			padding-top: 87px;
		}

		@include breakpoint(l) {
			margin-top: 99px;
		}

		@include breakpoint(l) {
			margin-top: 128px;
		}
	}*/

	/*&__image {
		@include responsive-container-psudo(789, 215);
		position: absolute;
		z-index: 2;
		width: 100%;
		max-width: 280px;
		max-height: 76px;
		top: -40px;
		left: 50%;
		transform: translateX(-50%);
		background: url(../../images/vegetables/vegetables-426.png);
		background-size: cover;

		@include breakpoint(ml) {
			max-width: 426px;
			max-height: 116px;
			top: -72px;
			background-image: url(../../images/vegetables/vegetables.png);
		}

		@include breakpoint(ts) {
			max-width: 640px;
			max-height: 174px;
			top: -127px;
			background-image: url(../../images/vegetables/vegetables.png);
		}

		@include breakpoint(l) {
			max-width: 789px;
			max-height: 215px;
			top: -156px;
		}
	}*/
}
