﻿.call-to-action {
	position: relative;
	height: 460px;
	background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 100%);

	@extend .rip-effect-bottom;

	&__title {
		@include font-h1();
		color: $color-white;
		text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
	}

	&__content {
		position: absolute;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;

		.container {
			width: 100%;

			@include breakpoint(ts) {
				width: 456px;
			}
		}
	}

	&__picture {
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 100%);
			opacity: 0.4;
		}
	}

	&__image {
		width: 100%;
		height: 460px;
		object-fit: cover;
		object-position: center;
	}

	&__link {
		@include font-menu();
		position: absolute;
		display: block;
		z-index: 1;
		bottom: 30px;
		left: 30px;
		color: $color-white;
		text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);

		&:after {
			@include icon($icon-arrow-right);
			position: relative;
			top: -2px;
			left: 2px;
			font-size: 7px;
			transition: left 0.3s ease-in-out;
		}

		&:hover:after {
			left: 5px;
		}
	}
}
