﻿.languages {
	position: relative;
	width: 100%;
	color: $color-white;
	z-index: 2;
	font: 16px/1.2em $font-title;

	@include breakpoint(l) {
		position: absolute;
		width: auto;
		margin: 0 -5px 0 0;
		top: 0;
		right: 20px;
		font-weight: 500;
	}

	&:hover {

		.languages__current {
			background: $color-brand;
		}

		dd {
			display: block;

			a {
				color: $color-white;
			}
		}
	}

	dd {
		min-height: unset;
		margin: 0;

		@include breakpoint(l) {
			position: absolute;
			display: none;
			right: 0;
		}
	}
}

.languages__current {
	position: relative;
	display: none;
	padding: 15px 20px 15px 10px;
	text-transform: uppercase;

	&:after {
		position: absolute;
		@include icon($icon-arrow-down);
		font-size: 8px;
		top: 21px;
		right: 5px;
	}

	@include breakpoint(l) {
		display: block;
	}
}

.languages__list {
	list-style: none;
	//text-align: right;
	padding: 0;
	margin: 0;
	right: 0;

	@include breakpoint(l) {
		background: $color-brand;
	}

	li {
		a {
			display: block;
			padding: 2.5px 10px;
		}

		&:hover a {
			text-decoration: underline;
		}
	}

	li:first-child a {
		padding-top: 10px;
	}

	li:last-child a {
		padding-bottom: 10px;
	}
}