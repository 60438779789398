﻿footer,
.footer {

	&__background {
		padding: 56px 0 54px;
		background: url(../../images/kraft-background/kraft-background-426.png);

		@extend .rip-effect-kraft;

		@include breakpoint(ml) {
			background-image: url(../../images/kraft-background/kraft-background-768.png);
		}

		@include breakpoint(t) {
			background-image: url(../../images/kraft-background/kraft-background-1024.png);
		}

		@include breakpoint(l) {
			background-image: url(../../images/kraft-background/kraft-background-1440.png);
		}

		@include breakpoint(ll) {
			background-image: url(../../images/kraft-background/kraft-background-1920.png);
		}
	}

	&__usp {
		@include font-h3;
		text-align: center;
		margin: 0 0 52px;
	}

	&__content {
		margin: 0 0 54px;
	}
}
