﻿.video__wrapper {
	max-width: 1030px;
	margin: 28px auto 20px;
}

.video {
	@include responsive-container(1030, 579);

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}