﻿.recipe-header {
	overflow: hidden;

	&__image-container {
		float: left;
		width: 100%;

		@include breakpoint(ts) {
			width: 50%;
		}

		@include breakpoint(l) {
			width: (100% / 3 * 2);
		}
	}

	&__image {
		min-height: 100%;
		object-fit: cover;
		object-position: center;
	}

	&__content {
		position: relative;
		float: left;
		width: 100%;
		padding: 56px 32px 25px;
		background: url(../../images/kraft-background/kraft-background-426.png);

		@include breakpoint(ts) {
			width: 50%;
		}

		@include breakpoint(l) {
			width: (100% / 3);
		}
	}

	&__preperation-time,
	&__amount-of-people,
	&__author {
		@include font-list();
		display: block;
		margin-bottom: 24px;
	}

	&__preperation-time,
	&__amount-of-people {
		position: relative;

		&:before {
			position: absolute;
			font-size: 24px;
			top: -3px;
			left: 0;
		}
	}

	&__preperation-time {
		padding-left: 34px;

		&:before {
			@include icon($icon-clock);
		}
	}

	&__amount-of-people {
		padding-left: 44px;

		&:before {
			@include icon($icon-amount-of-people);
		}
	}

	&__author {
		font-style: italic;
		font-weight: bold;
	}
}
