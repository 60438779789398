﻿.paging {
	margin: -34px 0 26px;

	&__back-to-overview {
		position: relative;
		float: left;
		padding-left: 15px;
		color: $color-red;

		&:before {
			@include icon($icon-arrow-left);
			position: absolute;
			font-size: 7px;
			top: 8px;
			left: 0;
		}
	}

	&__container {
		float: right;
		overflow: hidden;
		width: 100%;

		@include breakpoint(ml) {
			width: auto;
		}
	}

	&__index {
		float: left;
	}

	&__next,
	&__previous {
		float: left;
		position: relative;
		display: block;
		width: 20px;
		height: 20px;
		top: 1px;


		&:before {
			@include icon($icon-arrow-left);
			font-size: 7px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__previous {
		right: 2px;

		&:before {
			@include icon($icon-arrow-left);
		}
	}

	&__next {
		left: 2px;

		&:before {
			@include icon($icon-arrow-right);
		}
	}
}
