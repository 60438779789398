﻿.legal {
	@include font-legal;
	position: relative;
	padding: 13px 0;
	margin: 0 auto;
	color: $color-brand-dark;

	@extend .rip-effect;

	ul {
		@include clearfix;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		position: relative;
		float: left;
		margin-right: 36px;
		padding-top: 4px;
		padding-bottom: 4px;

		+ li:before {
			position: absolute;
			display: none;
			content: '';
			height: 12px;
			width: 1px;
			top: 6px;
			left: -18px;
			background: $color-brand-dark;

			@include breakpoint(ts) {
				display: block;
			}
		}
	}

	a {

		&:hover {
			text-decoration: underline;
		}
	}
}

li.-panorama {

	a {
		//color: $color-primary;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

li.-concept {
	position: relative;
	margin: 16px 0 0 0;
	width: 100%;

	@include breakpoint(l) {
		width: auto;
	}

	a {
		display: block;
		text-transform: uppercase;
		font-style: italic;

		@include breakpoint(l) {
			padding-right: 187px;
		}

		&:after {
			position: relative;
			display: inline-block;
			content: '';
			width: 177px;
			height: 41px;
			top: 13px;
			left: 0;
			background: url(../../images/logo-vreugdenhil.png);

			@include breakpoint(l) {
				position: absolute;
				top: -13px;
				right: 0;
				left: auto;
			}
		}
	}

	&:before {
		display: none !important;
	}
}