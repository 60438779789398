.slideshow {

	@include breakpoint(l) {
		margin-top: -200px;
	}

	&__item {
		height: 560px;

		@include breakpoint(t) {
			height: 660px;
		}

		@include breakpoint(lm) {
			height: 760px;
		}

		@include breakpoint(ll) {
			height: 860px;
		}

		&:nth-child(n + 2) {
			display: none;

			.slick-slider & {
				display: block;
			}
		}

		&:before {
			position: absolute;
			z-index: 1;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 100%);
			opacity: 0.4;
		}
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	&__content {
		position: absolute;
		z-index: 2;
		width: 100%;
		top: 180px;
		left: 0;

		@include breakpoint(l) {
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__title {
		@include font-h1();
		line-height: 60px;
		color: $color-white;
		text-align: center;
	}

	&__wrapper {
		position: relative;
	}
}
