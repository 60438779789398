﻿$icon-amount-of-people: "\ea01";
$icon-arrow-down: "\ea02";
$icon-arrow-left: "\ea03";
$icon-arrow-right: "\ea04";
$icon-arrow-up: "\ea05";
$icon-check: "\ea06";
$icon-clock: "\ea07";
$icon-close: "\ea08";
$icon-facebook: "\ea09";
$icon-instagram: "\ea0a";
$icon-linkedin: "\ea0b";
$icon-menu: "\ea0c";
$icon-pinterest: "\ea0d";
$icon-twitter: "\ea0e";
$icon-whatsapp: "\ea0f";

@at-root {
    @font-face {
        font-family: icon;
        
        src: url("../../fonts/icon.woff2") format("woff2"), url("../../fonts/icon.woff") format("woff"); 
        font-style: normal;
        font-weight: normal;
    }
}

@mixin icon($content: none) {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-family: icon;
    line-height: 1;
    text-transform: none;
    speak: none;
    content: $content;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon:before {
    @include icon;
}

.icon-amount-of-people:before { content: $icon-amount-of-people; }
.icon-arrow-down:before { content: $icon-arrow-down; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-arrow-up:before { content: $icon-arrow-up; }
.icon-check:before { content: $icon-check; }
.icon-clock:before { content: $icon-clock; }
.icon-close:before { content: $icon-close; }
.icon-facebook:before { content: $icon-facebook; }
.icon-instagram:before { content: $icon-instagram; }
.icon-linkedin:before { content: $icon-linkedin; }
.icon-menu:before { content: $icon-menu; }
.icon-pinterest:before { content: $icon-pinterest; }
.icon-twitter:before { content: $icon-twitter; }
.icon-whatsapp:before { content: $icon-whatsapp; }
