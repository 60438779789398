.rip-effect {
	position: relative;

	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 13px;
		top: -13px;
		left: 0;
		background: url(../../images/section/section-top-426.png) top center;

		@include breakpoint(ml) {
			background-image: url(../../images/section/section-top-768.png);
		}

		@include breakpoint(t) {
			background-image: url(../../images/section/section-top-1024.png);
		}

		@include breakpoint(l) {
			background-image: url(../../images/section/section-top-1440.png);
		}

		@include breakpoint(ll) {
			background-image: url(../../images/section/section-top-1600.png);
		}
	}
}

.rip-effect-bottom {
	@extend .rip-effect;

	&:after {
		top: -1px;
		background-image: url(../../images/section/section-bottom-426.png);

		@include breakpoint(ml) {
			background-image: url(../../images/section/section-bottom-768.png);
		}

		@include breakpoint(t) {
			background-image: url(../../images/section/section-bottom-1024.png);
		}

		@include breakpoint(l) {
			background-image: url(../../images/section/section-bottom-1440.png);
		}

		@include breakpoint(ll) {
			background-image: url(../../images/section/section-bottom-1600.png);
		}
	}
}

.rip-effect-kraft {
	@extend .rip-effect;

	&:after {
		background-image: url(../../images/kraft-background/kraft-background-top-426.png);

		@include breakpoint(ml) {
			background-image: url(../../images/kraft-background/kraft-background-top-768.png);
		}

		@include breakpoint(t) {
			background-image: url(../../images/kraft-background/kraft-background-top-1024.png);
		}

		@include breakpoint(l) {
			background-image: url(../../images/kraft-background/kraft-background-top-1440.png);
		}

		@include breakpoint(ll) {
			background-image: url(../../images/kraft-background/kraft-background-top-1920.png);
		}
	}
}