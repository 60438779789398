﻿header,
.header {
	position: relative;
	min-height: 300px;
	overflow: hidden;

	&__picture {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 100%);
			opacity: 0.4;
		}
	}

	&__image {
		width: 100%;
		height: 300px;
		object-fit: cover;
		object-position: center;
	}
}
