﻿.share {
	margin: 0;


	@include breakpoint(lm) {
		position: absolute;
		bottom: 54px;
		left: 32px;
	}

	&__title {
		@include font-h3();
		text-transform: uppercase;
	}

	&__link {
		position: relative;
		display: inline-block;
		width: 26px;
		height: 26px;
		text-indent: -9999px;
		overflow: hidden;
		margin: 0 10px 0;
		bottom: -6px;

		&:after {
			position: absolute;
			font-size: 21px;
			top: 50%;
			left: 50%;
			z-index: 1;
			transform: translate(-50%, -50%);
			text-indent: 0;
			transition: transform 0.2s ease-in-out;
		}

		&.-facebook:after {
			@include icon($icon-facebook);
		}

		&.-twitter:after {
			@include icon($icon-twitter);
		}

		&.-whatsapp:after {
			@include icon($icon-whatsapp);
		}

		&:hover {

			&:after {
				transform: translate(-50%, -50%) scale(1.2);
			}
		}
	}
}
