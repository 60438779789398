﻿.random-product {
	@include font-random-product();
	display: none;
	position: absolute;
	width: 222px;
	top: 60px;
	right: -10px;
	text-align: right;
	word-spacing: 100px;

	@include breakpoint(ll) {
		display: block;
	}

	@include breakpoint(ds) {
		width: 322px;
		right: -30px;
	}

	&__text {
		display: block;
		padding-right: 35px;

		@include breakpoint(ds) {
			padding-right: 75px;
		}
	}
}
